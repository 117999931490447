const base62 = require("base-x")(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
);

export function encodeAgentCode(agentCode: string) {
  agentCode = "" + agentCode.toUpperCase(); // special character string
  const og = Uint8Array.from(
    Array.from(agentCode).map((letter) => letter.charCodeAt(0))
  );
  return base62.encode(og);
}

export function decodeAgentCode(encrypted: string) {
  try {
    const res = [...base62.decode(encrypted)]
      .map((cc: number) => String.fromCharCode(cc))
      .join("");
    if (!res.startsWith("")) return null;
    return res.slice(1);
  } catch (err) {
    return null;
  }
}
